<script setup>
/* eslint-disable */
import { useMissionsStore } from '@/stores/missions';

const missionsStore	= useMissionsStore();

</script>
<template>
	<div class="league-slide-item">
		<div class="live-legaue-card">
			<div class="live-card-inner">
				<div class="live-card-image">
					<img src="/assets/images/mission_slider.jpg" alt="promo-card-1">
				</div>
				<div class="live-card-content">
					<div class="league-title">
						<div class="title">
							<img src="/assets/white_sport_icons/Archery.svg" alt="">
							<h6>{{ $t('missions.missions') }}</h6>
						</div>
						<div class="score_time" style="display: flex; justify-content: flex-end; gap: 10px; align-items: center;">
							<span>{{ $t('missions.total') }} : </span>
							<div class="circle-background">
								<span style="margin-top: -3px;">{{ missionsStore.getSportsbookMissions.length }}</span>
							</div>
						</div>
					</div>
					<div class="betting_info">
						<p style="font-weight: 300; color: #ffffff; width: 100%; font-size: 12px;">{{ $t('missions.latest_mission')}} <br>
							<p style="font-weight: 700; font-size: 24px;">{{ missionsStore.getSportsbookMissions[0].name }}</p>
							<p v-if="missionsStore.getSportsbookMissions.length > 1" style="font-weight: 300; text-align: end;">{{ $t('missions.and_many_more') }}...</p>
						</p>
					</div>

				</div>
			</div>
		</div>

	</div>
</template>

<style scoped>
 .live-legaue-card .live-card-inner .live-card-image img {
	mix-blend-mode: unset !important;
}

.circle-background {
	background-color: #63A246;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 12px;
	font-weight: 100;
}
</style>