import _ from 'lodash';
import { defineStore } from 'pinia';

export const useMissionsStore = defineStore('missions', {
	state: () => ({
		selected_mission: null,
		mission_check: null,
		mission_casino_check: null,
		missions: [],
		mission_type: 'all',
	}),
	getters: {
		getSelectedMission: (state) => {
			if (state.selected_mission) {
				return _.find(state.missions, { id: state.selected_mission });
			}
		},
		getMissions: (state) => {
			return state.missions
		},
		getMissionBasedOnType: (state) => {
			if (state.mission_type == 'all') return state.missions;

			return state.missions.filter(mission => mission.mission_type === state.mission_type);
		},
		getSportsbookMissions: (state) => {
			return state.missions.filter(mission => {
				return mission.mission_type === 'sportsbook' || mission.mission_type === 'both';
			});
		},
		getCasinoMissions: (state) => {
			return state.missions.filter(mission => {
				return mission.mission_type === 'casino' || mission.mission_type === 'both';
			});
		},
	},
	actions: {
		fetchCustomerMissions() {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/fetch/customer').then((response) => {
				this.missions = response.data.missions;
				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		fetchMissions(language_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/fetch', { language_id: language_id }).then((response) => {
				this.missions = response.data.missions;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		claimMission(mission_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/claim', { mission_id: mission_id }).then((response) => {

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		checkSportbookMission(mission_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/check', { mission_id: mission_id }).then((response) => {
				this.mission_check = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		checkCasinoMission(mission_id) {
			return global.axios.post(process.env.VUE_APP_API_URL + '/missions/check-freespin', { mission_id: mission_id }).then((response) => {
				this.mission_casino_check = response.data;

				return Promise.resolve(response.data);
			}).catch((error) => {
				return Promise.reject(error.response);
			})
		},
		setSelectedMission(mission_id) {
			this.selected_mission = mission_id;
		}
	},
})