<script setup>
/* eslint-disable */
import { onMounted, onUnmounted, watch, ref } from 'vue';
import { useSocketStore } from '@/stores/socket';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useMarketsStore } from '@/stores/markets';
import { useGeneralStore } from '@/stores/general';
import { useRouter, useRoute } from 'vue-router';
import { useSportPopularStore } from '@/stores/sport_popular';

import EsportFeaturedFixtures from '@/components/esports/EsportFeaturedFixtures.vue';
import LiveOverview from '@/components/esports/highlights/LiveOverview.vue';
import AllUpcomingOverview from '@/components/esports/highlights/AllUpcomingOverview.vue';
import PopularOverview from '@/components/esports/highlights/PopularOverview.vue';
import { useI18n } from 'vue-i18n';

const socketStore = useSocketStore();
const preSportsStore = usePreSportsStore();
const liveSportsStore = useLiveSportsStore();
const marketsStore = useMarketsStore();
const liveSportOverviewStore = useLiveSportOverview();
const generalStore = useGeneralStore();
const sportPopularStore = useSportPopularStore();

const router = useRouter();
const route = useRoute();

const { t } = useI18n();

const active_tab = ref('upcoming');

const show_scroll_to_top_button = ref(false);

const show_primary_overview_market = ref(false);
const show_secondary_overview_market = ref(false);

const primary_overview_market = ref(null);
const secondary_overview_market = ref(null);

const changeTab = (tab) => {
	active_tab.value = tab;
}

const selectPrimaryOverviewMarket = (overview_market) => {
	primary_overview_market.value = overview_market;
	show_primary_overview_market.value = false;
}

const selectSecondaryOverviewMarket = (overview_market) => {
	secondary_overview_market.value = overview_market;
	show_secondary_overview_market.value = false;
}

const closePrimaryOverviewMarketSelector = () => {
	show_primary_overview_market.value = false;
}

const closeSecondaryOverviewMarketSelector = () => {
	show_secondary_overview_market.value = false;
}

socketStore.getSocket.on('sportHighlights', (data) => {
	sportPopularStore.setPopularFixtures(data);
})

socketStore.getSocket.on('sportHighlightsUpdate', (data) => {
	sportPopularStore.updatePopularFixtures(data);
});

socketStore.getSocket.on('liveSportOverview', (data) => {
	liveSportOverviewStore.setOverview(data);
});

socketStore.getSocket.on('liveSportOverviewUpdate', (data) => {
	liveSportOverviewStore.updateOverview(data);
});
const handleScroll = () => {
	const scrollTop = window.scrollY;
	show_scroll_to_top_button.value = scrollTop > 100;
};
const scrollToTop = () => {
	const container = document.querySelector('.live-tab-info');
	window.scrollTo({ top: 0 });
};
onMounted(() => {
  if (router.currentRoute.value.params.sport_id) {
    let selected_esport = _.find(preSportsStore.esports, {'id': Number(router.currentRoute.value.params.sport_id) });
    if (selected_esport) document.title = generalStore.getGeneral.site_name + ' - ' + selected_esport.name + ' ' + t('general.highlights');
    
    let sport_overview_markets = marketsStore.sportOverviewMarkets(router.currentRoute.value.params.sport_id);
    if (Array.isArray(sport_overview_markets) && sport_overview_markets.length > 1) {
      primary_overview_market.value = sport_overview_markets[0];
      secondary_overview_market.value = sport_overview_markets[1];
    } else if (Array.isArray(sport_overview_markets) && sport_overview_markets.length > 0) {
      primary_overview_market.value = sport_overview_markets[0];
      secondary_overview_market.value = sport_overview_markets[0];
    }

    socketStore.getSocket.emit('join-sportHighlights', router.currentRoute.value.params.sport_id);
    socketStore.getSocket.emit('join-liveSportOverview', router.currentRoute.value.params.sport_id);
    window.addEventListener('scroll', handleScroll);
  }
});

onUnmounted( () => {
	socketStore.getSocket.emit('leave-sportHighlights', preSportsStore.selectedEsportId);
	socketStore.getSocket.emit('leave-liveSportOverview');
	liveSportOverviewStore.emptyLiveSportsOverview();
})

watch(() => preSportsStore.esports, (newSports, oldSports) => {
	if (oldSports.length == 0 && newSports.length > 0) {
		let selected_esport = _.find(preSportsStore.esports, {'id': Number(router.currentRoute.value.params.sport_id) });
		if (!selected_esport) return;
		document.title = generalStore.getGeneral.site_name + ' - ' + selected_esport.name + ' ' + t('general.highlights');
	}
})

watch(route, (to, from) => {
	if (!to.params.hasOwnProperty('sport_id') || to.params.sport_id == '') return;

	socketStore.getSocket.emit('join-sportHighlights', to.params.sport_id);
	socketStore.getSocket.emit('join-liveSportOverview', to.params.sport_id);

	let sport_overview_markets = marketsStore.sportOverviewMarkets(router.currentRoute.value.params.sport_id);
	if (sport_overview_markets.length > 1) {
		primary_overview_market.value = sport_overview_markets[0];

		secondary_overview_market.value = sport_overview_markets[1]
	} else {
		primary_overview_market.value = sport_overview_markets[0];
		secondary_overview_market.value = sport_overview_markets[0]
	}

	let selected_esport = _.find(preSportsStore.esports, {'id': Number(router.currentRoute.value.params.sport_id) });
	document.title = generalStore.getGeneral.site_name + ' - ' + selected_esport.name + ' ' + t('general.highlights');

});

</script>

<template>


	<div class="live-bet-dashboard round-games-main">
		<div class="bet-dashboard-top">
			<!-- <div class="tabing-dashboard">
				<div class="tab-pills">
					<button class="tab-link" :class="active_tab == 'upcoming' ? 'active' : ''" data-tab-id="upcoming" @click="changeTab('upcoming')">{{$t('general.upcoming')}}</button>
					<button v-if="sportPopularStore.getTopFeaturedFixtures.length > 0" class="tab-link" :class="active_tab == 'popular' ? 'active' : ''" data-tab-id="popular" @click="changeTab('popular')">{{$t('general.popular')}}</button>
					<button v-if="liveSportOverviewStore.overviewFixturesCount > 0" class="tab-link last-btn" :class="active_tab == 'live' ? 'active' : ''" data-tab-id="live" @click="changeTab('live')">{{ $t('general.live') }} ({{ liveSportOverviewStore.overviewFixturesCount }})</button>
				</div>
			</div> -->
			<div class="dashboard-info-label bet-tab-title" >
				<h3 v-if="active_tab == 'upcoming'">{{ $t('general.upcoming') }}</h3>
				<h3 v-if="active_tab == 'popular'">{{ $t('general.popular') }}</h3>
				<h3 v-if="active_tab == 'live'">{{ $t('general.live') }} ({{ liveSportOverviewStore.overviewFixturesCount }})</h3>
			</div>
			<div class="dashboard-info-label">
				<div class="sport-title">
				<img :src="preSportsStore.selectedPreSportIcon" alt="Selected Sport Icon">
				<h6>{{ preSportsStore.selectedPreSportName }}</h6>
				</div>
			</div>
			<div class="dashboard-info-label dropdown-option-top" v-if="primary_overview_market">
				<div class="select-option">
					<dl class="drop" :class="show_primary_overview_market ? 'active' : ''">
						<span class="tabIco">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
								<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
									<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
								</g>
							</svg>
						</span>
						<dt><button class="tablinks" @click="show_primary_overview_market = !show_primary_overview_market"  v-click-outside-element="closePrimaryOverviewMarketSelector">{{ primary_overview_market.name.toUpperCase() }}</button>
						</dt>
						<dd>
							<ul>
								<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selectedEsportId)" :key="overview_market.slug" @click="selectPrimaryOverviewMarket(overview_market)" :class="primary_overview_market.slug == overview_market.slug ? 'active' : ''">
									<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
								</li>
							</ul>
						</dd>
					</dl>
				</div>
			</div>

			<div class="dashboard-info-label dropdown-option-top dropdown-option-top-2" v-if="secondary_overview_market">
				<div class="select-option">
					<dl class="drop" :class="show_secondary_overview_market ? 'active' : ''">
						<span class="tabIco">
							<svg xmlns="http://www.w3.org/2000/svg" width="4" height="3" viewBox="0 0 4 3">
								<g id="Group_73" data-name="Group 73" transform="translate(-134 -568)">
									<path id="Polygon_2" data-name="Polygon 2" d="M2,0,4,3H0Z" transform="translate(138 571) rotate(180)" fill="#fff" />
								</g>
							</svg>
						</span>
						<dt><button class="tablinks" @click="show_secondary_overview_market = !show_secondary_overview_market"  v-click-outside-element="closeSecondaryOverviewMarketSelector">{{ secondary_overview_market.name.toUpperCase() }}</button>
						</dt>
						<dd>
							<ul>
								<li v-for="overview_market in marketsStore.sportOverviewMarkets(preSportsStore.selectedEsportId)" :key="overview_market.slug" @click="selectSecondaryOverviewMarket(overview_market)" :class="secondary_overview_market.slug == overview_market.slug ? 'active' : ''">
									<button class="tablinks">{{ overview_market.name.toUpperCase() }}</button>
								</li>
							</ul>
						</dd>
					</dl>
				</div>
			</div>
		</div>

		<div class="live-tab-info" id="tabs">
			<AllUpcomingOverview  :primary_overview_market="primary_overview_market" :secondary_overview_market="secondary_overview_market"/>
		</div>
	</div>
	<button @click="scrollToTop" type="button" class="btn scroll-to-top" id="btn-back-to-top" v-show="show_scroll_to_top_button">
		<i class="fas fa-arrow-up"></i>
	</button>
</template>
<style scoped>
@media screen and (min-width: 768px) and (max-width: 991px) {
	.live-bet-dashboard .live-tab-info {
		max-height: unset;
	}
}
@media screen and (min-width: 992px) and (max-width: 3000px) {
	button#btn-back-to-top {
		display:none;
	}
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		bottom: 70px;
		left: 20px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	svg.svg-inline--fa.fa-arrow-up.fa-w-14 {
		margin-bottom: 7px!important;
	}
	button#btn-back-to-top {
		width: 30px!important;
		height: 30px!important;
		font-size: 14px!important;
	}
}
button#btn-back-to-top {
	background: #63A246;
	width: 38px;
	height: 38px;
	border: none;
	border-radius: 50%;
	position: fixed;
	z-index: 99;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	font-size: 16px;
	cursor: pointer;
}
html[dir="rtl"] button#btn-back-to-top {
	left: unset;
	right:20px;
}
@media screen and (min-width: 320px) and (max-width: 991px) {
	button#btn-back-to-top {
		left: 10px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	html[dir="rtl"] button#btn-back-to-top {
		left: unset;
		right:10px;
	}
}
.live-bet-dashboard.round-games-main .bet-dashboard-top:after {
	width: 100% !important;
}
@media screen and (min-width: 768px) {
	.tab-pills {
		padding-bottom: 12px;
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.dropdown-option-top-2 {
		display: none;
	}
}
@media screen and (min-width: 320px) and (max-width: 323px) {
	.last-btn {
		margin-top: 5px;
	}
}

html[dir="rtl"] .live-bet-dashboard .bet-dashboard-top:after {
	right: unset;
	left: 0px;
}

</style>