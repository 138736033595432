<script setup>
import { ref, onMounted, watch } from "vue";
import { useMenuStore } from "@/stores/menus";
import { useInitializeStore } from '@/stores/initialize';
import Cookie from '../src/components/modals/Cookie.vue';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useFooterSectionStore } from '@/stores/footer_sections';
import { useMissionsStore } from '@/stores/missions';
import { useLanguagesStore } from "./stores/languages";
import { useCustomerStore } from "./stores/customer";
import { useRouter } from "vue-router";
import { openModal } from "@kolirt/vue-modal";
import SportsbookMissions from "./components/modals/missions/SportsbookMissions.vue";

const initializeStore = useInitializeStore();
const menuStore = useMenuStore();
const footerSectionStore = useFooterSectionStore();
const device_type = ref(menuStore.isMobile == true ? 'mobile' : 'desktop')
const missionsStore = useMissionsStore();
const languagesStore = useLanguagesStore();
const customerStore = useCustomerStore();
const router = useRouter();

watch(() => device_type.value, (newValue) => {
	if (newValue) {
		menuStore.fetchMenus(device_type.value)
	}
})

onMounted(() => {
	usePreSportsStore().fetchPreSports();

	if (customerStore.isLoggedIn) {
		missionsStore.fetchCustomerMissions().then(() => {
			if (router.currentRoute.value.name == 'missions-popup') openModal(SportsbookMissions, {mission_type: 'all'}).catch(() => {});
		});
	} else {
		missionsStore.fetchMissions(languagesStore.selected_language.id).then(() => {
			if (router.currentRoute.value.name == 'missions-popup') openModal(SportsbookMissions, {mission_type: 'all'}).catch(() => {});
		});
	}

	window.addEventListener("resize", () => {
		initializeStore.updateScreenWidth();
	});

	footerSectionStore.fetchFooterSections();
})

</script>
<template>
	<RouterView />
	<ModalTarget/>
	<Cookie />
</template>
