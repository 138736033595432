<script setup>
/* eslint-disable */
import { onBeforeMount, onMounted, ref } from 'vue';
import { useMissionsStore } from '@/stores/missions';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import SportsbookMissionComponent from './SportsbookMissionComponent.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { closeModal } from '@kolirt/vue-modal';

const missionsStore = useMissionsStore();
const direction = ref(document.dir);
const svg_data = commonSvgs;
const missions = ref([]);
const options = ref({});

const props = defineProps({
	mission_type: {
		type: String,
		required: true
	}
})

onBeforeMount(() => {
	if (props.mission_type == 'all') missions.value = missionsStore.missions;
	else if (props.mission_type == 'sportsbook') missions.value = missionsStore.getSportsbookMissions;
	else if (props.mission_type == 'casino') missions.value = missionsStore.getCasinoMissions;
})

onMounted(() => {
	options.value = {
		direction: direction,
		rewind: true,
		pagination: true,
		perPage: 1,
		arrows: missions.value.length > 1,
		type: 'loop',
		autoplay: true,
		interval: 5000,
	}
})

</script>
<template>
	<div style="max-width: 320px; max-height: 535px" v-if="missions && missions.length > 0">
		<Splide :options="options">
			<SplideSlide v-for="(mission, index) in missions" :key="index">
				<SportsbookMissionComponent :mission="mission" />
			</SplideSlide>
		</Splide>
	</div>
	<div v-else class="dark-backgound">
		<div class="close">
			<object v-html="svg_data.form_cross" @click="closeModal()" style="cursor: pointer;"></object>
		</div>
		<div class="title">
			<p>{{ $t('missions.no_missions')}}</p>
		</div>
	</div>
</template>
<style scoped>
.dark-backgound {
	background-color: #131C27;
	height: 200px;
	width: 340px;
	display: flex;
	flex-direction: column;
}

.close {
	display: flex;
	justify-content: flex-end;
	padding: 10px;
}

.title {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	margin-top: -20px;
}

.dark-backgound p {
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 10px;
	text-align: center;
}

@media screen and (max-width: 767px) {
	.dark-backgound {
		width: 100%;
		height: 200px;
	}
}
</style>