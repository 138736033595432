<script setup>
/* eslint-disable */
import { onMounted, ref, computed } from 'vue';
import { useCurrenciesStore } from '@/stores/currencies';
import { useMissionsStore } from '@/stores/missions';
import { useCustomerStore } from '@/stores/customer';
import { useDatesStore } from '@/stores/dates';
import { closeModal, openModal } from '@kolirt/vue-modal'
import { useI18n } from 'vue-i18n';
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import Loader from '@/components/Common/Loader.vue';
import Swal from 'sweetalert2'
import Login from '@/components/modals/Login.vue';

const currencyStore = useCurrenciesStore();
const missionsStore = useMissionsStore();
const datesStore = useDatesStore();
const customerStore = useCustomerStore();
const { t } = useI18n();

const svg_data = commonSvgs;
const selected_mission = ref(null);
const loading = ref(false);
const show_rules = ref(true);
const show_description = ref(false);
const loader_loading = ref(false);

//Make the first letter capital if it is string
const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

const claimMission = (mission_id) => {
	loading.value = true;

	missionsStore.claimMission(mission_id).then((response) => {
		loading.value = false;
		closeModal();
		fireSwal('success', t('missions.mission_claimed'));
	}).catch((error) => {
		loading.value = false;
		closeModal();
		if (error.status == 422) fireSwal('error', t('missions.already_claimed'));
		else fireSwal('error', t('general.try_again'));
	});
}

const fireSwal = (alert_type, alert_message) => {
	Swal.fire({
		text: alert_message,
		icon: alert_type,
		confirmButtonText: alert_type == 'success' ? t('account.register.close') : t('general.try_again'),
	});
}

const calculateProgressbarColor = computed(() => {
	if (missionsStore.mission_check.progress < 25) return '#FF0000';
	if (missionsStore.mission_check.progress < 50) return '#FF5E01';
	if (missionsStore.mission_check.progress < 75) return '#FFBB02';
	if (missionsStore.mission_check.progress < 100) return '#B1AF24';
	else return '#63A246';
});

const calculateProgressbarCasinoColor = computed(() => {
	if (missionsStore.mission_casino_check.progress < 25) return '#FF0000';
	if (missionsStore.mission_casino_check.progress < 50) return '#FF5E01';
	if (missionsStore.mission_casino_check.progress < 75) return '#FFBB02';
	if (missionsStore.mission_casino_check.progress < 100) return '#B1AF24';
	else return '#63A246';
});

const openLoginModal = () => {
	closeModal();
	setTimeout(() => {
		openModal(Login).catch(() => {});
	}, 200);
}

const clickOutsideModal = (event) => {
	let modal = document.getElementById('mission-modal');
	if (modal && !modal.contains(event.target)) closeModal();
};

onMounted(() => {
	selected_mission.value = missionsStore.getSelectedMission;

	if (customerStore.isLoggedIn) {
		loader_loading.value = true;
		missionsStore.checkSportbookMission(selected_mission.value.id).then((response) => {
			missionsStore.checkCasinoMission(selected_mission.value.id).then((response) => {
				loader_loading.value = false;
			});
		});
	}
	document.addEventListener('click', clickOutsideModal);
});

</script>
<template>
	<div class="background" id="mission-modal">
		<template v-if="!selected_mission || loader_loading">
			<Loader/>
		</template>
		<template v-else>
			<div class="exit">
				<img src="/assets/white_sport_icons/Archery.svg" width="24" height="24" alt="">
				<h6>{{ $t('missions.missions') }}</h6>
				<object v-html="svg_data.form_cross" @click="closeModal" style="cursor: pointer;"></object>
			</div>
			<div class="container">
				<div class="title-container">
					<img :src="selected_mission.media" alt="" style="border-radius: 8px;"/>
					<div class="title-info">
						<p class="title-name">{{ selected_mission.name }}</p>
						<p>{{ $t('missions.available_until') }}</p>
						<p style="color: #63A246;"> {{ datesStore.defaultDate(selected_mission.available_until, true) }}</p>
					</div>
				</div>
				<div v-if="selected_mission.rewards.freespin_quantity && selected_mission.rewards.freebet_amount" class="reward-container">
					<h6>{{ $t('missions.win_rewards') }}: </h6>
					<div class="reward-box-wrapper">
						<div class="reward-box">
							<img src="/assets/images/receipt.svg" width="30" alt="">
							<p>{{ selected_mission.rewards.freebet_amount }}{{ currencyStore.getCurrencySymbol }} <br> freebet</p>
						</div>
						<div class="reward-box">
							<img src="/assets/images/mission_slot.svg" width="30" alt="">
							<p>{{ selected_mission.rewards.freespin_quantity }} <br> freespins</p>
						</div>
					</div>
				</div>
				<div v-else-if="selected_mission.rewards.freebet_amount" class="reward-container">
					<h6>{{ $t('missions.win_reward') }}: </h6>
					<div class="reward-box">
						<img src="/assets/images/receipt.svg" width="30" alt="">
						<p>{{ selected_mission.rewards.freebet_amount }}{{ currencyStore.getCurrencySymbol }} <br> freebet</p>
					</div>
				</div>
				<div v-else-if="selected_mission.rewards.freespin_quantity" class="reward-container">
					<h6>{{ $t('missions.win_reward') }}: </h6>
					<div class="reward-box">
						<img src="/assets/images/receipt.svg" width="30" alt="">
						<p>{{ selected_mission.rewards.freespin_quantity }}{{ currencyStore.getCurrencySymbol }} <br> freespins</p>
					</div>
				</div>


				<div class="body-container">
					<div class="body-box">
						<div class="box-header" @click="show_rules = !show_rules">
							<h6>{{ $t('missions.rules') }}: </h6>
							<img :class="{ 'rotated': show_rules }" src="@/assets/images/arrow-select.svg" width="16" alt="">
						</div>
						<div class="body-box-inner" v-if="show_rules" style="margin-top: 15px;">
							<!-- Freebet section, visible when mission_type is 'both' or 'sportsbook' -->
							<div v-if="selected_mission.mission_type == 'sportsbook' || selected_mission.mission_type == 'both'" class="mission-section">
								<h6 v-if="selected_mission.mission_type == 'both'" style="font-size:16px;">{{ $t('missions.sportsbook_details') }}</h6>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.betslip_type') }}</p>
									<p class="rule-text-value">{{ capitalizeFirstLetter(selected_mission.betslip_type) }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.min_amount_per_betslip') }}</p>
									<p class="rule-text-value">{{ selected_mission.amount }} {{ currencyStore.getCurrencySymbol }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.min_price_per_bet') }}</p>
									<p class="rule-text-value">{{ selected_mission.minimum_price_per_bet }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.min_price_per_betslip') }}</p>
									<p class="rule-text-value">{{ selected_mission.minimum_price_per_betslip }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.min_matches') }}</p>
									<p class="rule-text-value">{{ selected_mission.minimum_matches }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.bet_type') }}</p>
									<p class="rule-text-value">{{ capitalizeFirstLetter(selected_mission.bet_type) }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.betslip_settlement') }}</p>
									<p class="rule-text-value">{{ capitalizeFirstLetter(selected_mission.betslip_settlement) }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.cashout_available') }}</p>
									<template v-if="selected_mission.can_cashout">
										<img src="@/assets/svgs/check.svg" width="18" alt="">
									</template>
									<template v-else>
										<img src="@/assets/svgs/cross.svg" width="14" alt="">
									</template>
								</div>
							</div>

							<!-- Freespin section, visible when mission_type is 'both' or 'casino' -->
							<div v-if="selected_mission.mission_type == 'casino' || selected_mission.mission_type == 'both'"  class="mission-section">
								<h6 style="padding-top:10px; font-size:16px;" v-if="selected_mission.mission_type == 'both'" >{{ $t('missions.casino_details') }}</h6>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.min_spin_stake') }}</p>
									<p class="rule-text-value">{{ selected_mission.minimum_spin_stake }} {{ currencyStore.getCurrencySymbol }}</p>
								</div>
								<div class="rule-row">
									<p class="rule-text-title">{{ $t('missions.slot_amount') }}</p>
									<p class="rule-text-value">{{ selected_mission.slot_amount }} {{ currencyStore.getCurrencySymbol }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="body-container">
					<div class="body-box">
						<div class="box-header" @click="show_description = !show_description">
							<h6>{{ $t('missions.mission_description') }}: </h6>
							<img :class="{ 'rotated': show_description }" src="@/assets/images/arrow-select.svg" width="16" alt="">
						</div>
						<div class="box-description" v-if="show_description" v-html="selected_mission.description.description ? selected_mission.description.description : selected_mission.description" style="margin-top: 15px;"></div>
					</div>
				</div>
				<template v-if="customerStore.isLoggedIn">
					<div v-if="selected_mission.rewards.freebet_amount">
						<div class="reward-container" v-if="missionsStore.mission_check.success">
							{{ (missionsStore.mission_check.progress < 100 || missionsStore.mission_casino_check?.progress < 100) ? $t('missions.claimed') : $t('missions.completed') }}
							<h6 style="font-size:16px;">{{ $t('missions.reward_freebet_progress') }}: </h6>
							<div class="progressbar-container">
								<div class="progress" :style="{ width: missionsStore.mission_check.progress + '%', backgroundColor: calculateProgressbarColor }">{{ missionsStore.mission_check.progress }}%</div>
							</div>
						</div>
					</div>
					<div v-if="selected_mission.rewards.freespin_quantity">
						<div class="reward-container" v-if="missionsStore.mission_casino_check && missionsStore.mission_casino_check.success">
							<h6 style="font-size:16px;">{{ $t('missions.reward_freespin_progress') }}: </h6>
							<div class="progressbar-container">
								<div class="progress" :style="{ width: missionsStore.mission_casino_check.progress + '%', backgroundColor: calculateProgressbarCasinoColor }">{{ missionsStore.mission_casino_check.progress }}%</div>
							</div>
						</div>
					</div>
					<template v-if="selected_mission.rewards.freespin_quantity && selected_mission.rewards.freebet_amount">
						<template v-if="missionsStore.mission_casino_check?.success && missionsStore.mission_check?.success">
							<button class="btn btn-secondary custom-style-disabled" disabled style="width: 100%;" v-if="!loading">
								{{ (missionsStore.mission_check.progress < 100 || missionsStore.mission_casino_check.progress < 100) ? $t('missions.claimed') : $t('missions.completed') }}
							</button>
						</template>
						<template v-else>
							<button class="btn btn-secondary custom-style" @click="claimMission(selected_mission.id)" style="width: 100%;" v-if="!loading">{{ $t('missions.claim_mission') }}</button>
							<button class="btn btn-secondary custom-style" type="button" style="width: 100%;" v-else><div class="loader-main"></div></button>
						</template>
					</template>
					<template v-else-if="selected_mission.rewards.freebet_amount">
						<template v-if="missionsStore.mission_check.success">
							<button class="btn btn-secondary custom-style-disabled" disabled style="width: 100%;" v-if="!loading">
								{{ (missionsStore.mission_check.progress < 100) ? $t('missions.claimed') : $t('missions.completed') }}
							</button>
						</template>
						<template v-else>
							<button class="btn btn-secondary custom-style" @click="claimMission(selected_mission.id)" style="width: 100%;" v-if="!loading">{{ $t('missions.claim_mission') }}</button>
							<button class="btn btn-secondary custom-style" type="button" style="width: 100%;" v-else><div class="loader-main"></div></button>
						</template>
					</template>
					<template v-else-if="selected_mission.rewards.freespin_quantity">
						<template v-if="missionsStore.mission_casino_check.success">
							<button class="btn btn-secondary custom-style-disabled" disabled style="width: 100%;" v-if="!loading">
								{{ (missionsStore.mission_casino_check.progress < 100) ? $t('missions.claimed') : $t('missions.completed') }}
							</button>
						</template>
						<template v-else>
							<button class="btn btn-secondary custom-style" @click="claimMission(selected_mission.id)" style="width: 100%;" v-if="!loading">{{ $t('missions.claim_mission') }}</button>
							<button class="btn btn-secondary custom-style" type="button" style="width: 100%;" v-else><div class="loader-main"></div></button>
						</template>
					</template>
				</template>
				<template v-else>
					<button class="btn btn-secondary custom-style" @click="openLoginModal()" style="width: 100%;">{{ $t('missions.join') }}</button>
				</template>
			</div>
		</template>
	</div>
</template>
<style scoped>
.background {
	background: #131C27;
	width: 20%;
	height: 100%;
	overflow-y: auto;
	position: absolute;
	right: 0px;
}

.reward-box-wrapper {
	display: flex;
	gap: 16px;
}

.exit {
	padding: 16px 16px 8px 16px;
	display: grid;
	grid-template-columns: 24px 1fr 30px;
	gap: 12px;
	align-items: center;
	color: #ffffff;
	font-weight: 400;
}
.container {
	padding: 16px;
}

.title-container {
	display: flex;
	gap: 8px;
	border: 1px solid #4E545D;
	border-radius: 16px;
	padding: 8px;
	background-color: #2D3745;
}

.title-container img {
	width: 150px;
	height: 200px;
}

.title-info {
	display: flex;
	flex-direction: column;
	color: #ffffff;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.title-name {
	margin-bottom: 10px;
	text-align: center;
	font-weight: 600;
}

.title {
	display: grid;
	grid-template-columns: 24px 1fr;
	align-items: center;
	gap: 10px;
	padding: 10px;
	color: #ffffff;
	padding: 0;
	margin-bottom: 10px
}

.title img {
	width: 24px;
	height: 24px;
}

.reward-container {
	margin-top: 20px;
	color: #ffffff;
}

.reward-box {
	display: grid;
	grid-template-columns: 30px 1fr;
	gap: 12px;
	align-items: center;
	width: 120px;
	padding: 8px;
	border: 1px solid #4E545D;
	border-radius: 16px;
	margin-top: 10px;
	font-size: 13px;
	font-weight: 600;
	background-color: #2D3745;
}

.body-container {
	margin-top: 20px;
	color: #ffffff;
}

.register-form-field label {
	display: flex;
	flex-direction: column;
	gap: 10px;
	font-size: 14px;
	font-weight: 300;
	color: #fff;
	padding-bottom: 8px;
}

.form-input {
	width: 100%;
	padding: 8px 15px;
	background-color: #202833;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 300;
	color: #fff;
}

.form-input option:disabled {
	background-color: #202833;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 300;
	color: #fff;
}

.register-form-field select {
	background-image: url('@/assets/images/arrow-select.svg');
	background-repeat: no-repeat;
	background-position: center right 14px;
}

.register-form-field select :disabled {
	color: #fff;
}

.body-box {
	padding: 8px;
	border: 1px solid #4E545D;
	border-radius: 16px;
	margin-top: 10px;
	margin-bottom: 20px;
	font-size: 13px;
	font-weight: 600;
	background-color: #2D3745;
}

.rules {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 600;
}

.body-box-inner {
	padding-left: 5%;
	padding-right: 5%;
}

.rule-row {
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	border-bottom: 1px solid #4E545D;
}

.rule-text-title {
	margin-top: 8px;
	padding-bottom: 3px;
	font-weight: 300;
}

.rule-text-value {
	margin-top: 8px;
	padding-bottom: 3px;
	font-weight: 600;
}

.custom-style {
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	padding: 7px;
	cursor: pointer;
}

.custom-style-disabled {
	font-size: 14px;
	font-weight: 500;
	border-radius: 4px;
	padding: 7px;
	cursor: default;
	background-color: transparent;
	color: #63A246;
	font-weight: 600;
	border: 1px solid #878A90;
}

.box-header {
	display: flex;
	justify-content: space-between;
	cursor: pointer;
}

.box-header h6 {
	font-size: 16px;
}

.rotated {
	transform: rotate(180deg);
}

@media screen and (min-width: 320px) and (max-width: 425px) {
	.background {
		width: 100%;
	}
}

@media screen and (min-width: 426px) and (max-width: 500px) {
	.background {
		width: 90%;
	}
}

@media screen and (min-width: 501px) and (max-width: 600px) {
	.background {
		width: 70%;
	}
}

@media screen and (min-width: 601px) and (max-width: 991px) {
	.background {
		width: 50%;
	}
}

@media screen and (min-width: 992px) and (max-width: 1700px) {
	.background {
		width: 30%;
	}
}

.progressbar-container {
	background-color: #2D3745;
	width: 100%;
	border-radius: 15px;
	margin-bottom: 20px;
	margin-top: 10px;
}

.progress {
	color: white;
	text-align: right;
	font-size: 15px;
	border-radius: 15px;
	padding-right: 10px;
}
</style>