<script setup>
/* eslint-disable */
import { useCurrenciesStore } from '@/stores/currencies';
import { closeModal, openModal } from '@kolirt/vue-modal'
import commonSvgs from '@/assets/svgs/commonSvgs.json';
import { useMissionsStore } from '@/stores/missions';
import MissionsSidebar from './MissionsSidebar.vue';

const currencyStore = useCurrenciesStore();
const missionsStore = useMissionsStore();

const svg_data = commonSvgs;

const props = defineProps({
	mission: {
		type: Object,
		required: true,
	},
});
const selectMission = (mission_id) => {
	missionsStore.setSelectedMission(mission_id);
	closeModal();
	setTimeout(() => {
		openModal(MissionsSidebar).then((data) => {}).catch(() =>{});
	}, 500);
}

</script>
<template>
	<div class="background">
		<div class="title">
			<img src="/assets/white_sport_icons/Archery.svg" alt="">
			<h6>{{ $t('missions.missions') }}</h6>
			<a @click="closeModal" style="cursor: pointer;">
				<object v-html="svg_data.form_cross_white"></object>
			</a>
		</div>
		<div class="title_container">
			<h6>{{ props.mission.name }}</h6>
		</div>

		<div class="subtitle_container">
			<h6 v-if="props.mission.mission_type === 'both'"> {{ $t('missions.complete_and_rewards') }}</h6>
			<h6 v-else> {{ $t('missions.complete_and_reward') }}</h6>
		</div>

		<div class="item_container">
			<div v-if="props.mission.rewards.freespin_quantity && props.mission.rewards.freebet_amount" class="item" style="text-align: center;">
				<div style="display: flex; align-items: center; justify-content: space-around; gap: 30px;">
					<!-- Freebet Section -->
					<div style="text-align: center;">
						<img src="/assets/images/receipt.svg" style="width: 60px; height: 65px;" alt="">
						<h2 style="margin: 0; font-size: 1.5rem; ">
							{{ props.mission.rewards.freebet_amount }} {{ currencyStore.getCurrencySymbol }}
						</h2>
						<p style="color: #ffffff;">Freebet</p>
					</div>
					<!-- Freespins Section -->
					<div style="text-align: center;">
						<img src="/assets/images/mission_slot.svg" style="width: 70px; height: 65px; margin-left: 10px;" alt="">
							<h2 style="margin: 0; font-size: 1.5rem;">
								{{ props.mission.rewards.freespin_quantity }}
							</h2>
							<p style="color: #ffffff;">Freespins</p>
					</div>
				</div>
			</div>
			<div v-else-if="props.mission.rewards.freebet_amount" class="item">
				<img src="/assets/images/receipt.svg" alt="">
				<h2>{{ props.mission.rewards.freebet_amount }} {{ currencyStore.getCurrencySymbol }}</h2>
				<p style="color: #ffffff;">Freebet</p>
			</div>
			<div v-else-if="props.mission.rewards.freespin_quantity" class="item">
				<img src="/assets/images/mission_slot.svg" style="width: 80px; height: 70px;" alt="">
				<div style="display: flex; align-items: center; gap: 5px;">
					<h2 style="margin: 0;">{{ props.mission.rewards.freespin_quantity }}</h2>
					<h6 style="color: #ffffff; margin-top: 5px; padding-left: 5px">Freespins</h6>
				</div>
			</div>

		</div>

		<div class="button_position">
			<div class="button_container">
				<button class="btn-small btn-secondary" style="width: 120px;" @click="selectMission(props.mission.id)">{{ $t('missions.learn_more') }}</button>
			</div>
		</div>
	</div>
</template>
<style scoped>
.title {
	display: grid;
	grid-template-columns: 24px 1fr 24px;
	align-items: center;
	gap: 10px;
	padding: 10px;
}
.title img {
	width: 24px;
}
.title h6 {
	color: #ffffff;
	margin-top: -2px;
}
.background {
	background-image: url('../../../../public/assets/images/missions_background.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	min-height: 535px;
	min-width: 320px;
}
.title_container {
	padding-top: 10%;
	text-align: center;
	color: #ffffff;
}
.subtitle_container {
	text-align: center;
	color: #ffffff;
	margin-top: 5%;
}

.item {
	display:flex;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
	flex-direction: column;
}
.item h2 {
	color: #FFBB02;
}

.button_position {
	position: absolute;
	bottom: 50px;
	width: 100%;
}

.button_container {
	display: flex;
	justify-content: center;
}
</style>