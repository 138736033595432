
<script setup>
/* eslint-disable */
import { onMounted, computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import LiveFixtureDoubleMarket from './LiveFixtureDoubleMarket.vue';
import commonSvgs from '@/assets/svgs/commonSvgs.json'
const svg_data = ref(commonSvgs);

const router = useRouter();
const liveSportsStore = useLiveSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const fixtureStore = useFixtureStore();

const competitionStates = ref({}); // Track the open/closed state for each competition
const competition_closed = ref({});
const props = defineProps({
	competitions_closed: {
		type: Boolean,
		required: true
	},
	competition: {
		type: Array,  // Example, adjust based on actual prop type
		required: true
	},
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});
const emit = defineEmits(['update:competitions_closed']);
// Watch for changes to `competitions_closed` prop
watch(() => props.competitions_closed, (newValue) => {
  // If `competitions_closed` is true, close all competitions
  if (newValue) {
    competitionStates.value = Object.keys(liveSportOverviewStore.highlightFixtures).reduce((acc, key) => {
      acc[key] = false; // Close all competitions
      return acc;
    }, {});
  } else {
    // If `competitions_closed` is false, open all competitions
    competitionStates.value = Object.keys(liveSportOverviewStore.highlightFixtures).reduce((acc, key) => {
      acc[key] = true; // Open all competitions
      return acc;
    }, {});
  }
});

// Toggle the competition state for a specific competition
const toggleCompetitionState = (index) => {
  // Toggle the specific competition's open/close state
  competitionStates.value[index] = !competitionStates.value[index];
};
</script>
<template>
	<div id="live" class="tab-content highlight-live">
		<div class="live-info-outer-main" v-for="(competition, index) in liveSportOverviewStore.highlightFixtures" :key="index">

			<div class="live-info-outer">
				<div class="live-info-inner">
					<div class="live-info-team">
						<div class="live-match">
							<div class="team-flag">
								<img :src="'/assets' + competition[0].region_icon_url" alt="">
							</div>
							<div class="live-match-name">
								<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
								<!-- <div class="live-badge" v-html="svg_data.live_badge"></div> -->
							</div>
						</div>
					</div>

					<!-- <div class="full-time-top top-title-dash">
						<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
					</div> -->

					<!-- <div class="match-goal-top top-title-dash">
						<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
							<span v-if="odd != ''">{{ odd }}</span>
						</template>
						<span class="bet-column-empty"></span>

					</div> -->
					<span class="bet-column-empty">
						<div @click="toggleCompetitionState(index)" class="market-arrow">
						<a class="market-arrow-icon" :class="competitionStates[index] ? 'market-arrow-icon-closed' : ''"></a>
					</div>
					
				</span>
				</div>
				<template v-if="!competitionStates[index]">
					<LiveFixtureDoubleMarket v-for="(fixture, index) in competition"
						:key="index"
						:primary_overview_market="primary_overview_market"
						:secondary_overview_market="secondary_overview_market"
						:fixture="fixture"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<style scoped>
html[dir="rtl"] .live-bet-dashboard .live-tab-info .live-info-outer .live-info-inner .live-info-team .live-match .live-match-name .live-badge {
	margin-right: 10px;
}

</style>