<script setup>
/* eslint-disable */
import { onMounted, computed, watch, ref } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { usePreSportsStore } from '@/stores/pre_sports';
import { useLiveSportsStore } from '@/stores/live_sports';
import { useLiveSportOverview } from '@/stores/live_sport_overview';
import { useFixtureStore } from '@/stores/fixture';
import { useSportUpcomingStore } from '@/stores/sport_upcoming';
import { useCouponsStore } from '@/stores/coupons';

import EsportFixtureDoubleMarket from './EsportFixtureDoubleMarket.vue';
import LoaderComponent from '@/components/Common/LoaderComponent.vue';

const router = useRouter();
const liveSportsStore = useLiveSportsStore();
const preSportsStore = usePreSportsStore();
const liveSportOverviewStore = useLiveSportOverview();
const fixtureStore = useFixtureStore();
const sportUpcomingStore = useSportUpcomingStore();
const couponsStore = useCouponsStore();

const competitionStates = ref({}); // Track the open/closed state for each competition
const competition_closed = ref(false);
const props = defineProps({
	competitions_closed: {
		type: Boolean,
		required: true
	},
	competition: {
		type: Array,  // Example, adjust based on actual prop type
		required: true
	},
	primary_overview_market: {
		type: Object,
		required: true,
	},
	secondary_overview_market: {
		type: Object,
		required: true,
	},
});
const emit = defineEmits(['update:competitions_closed']);
onMounted(() => {
	if (router.currentRoute.value.params.sport_id) {
		var esport = router.currentRoute.value.params.sport_id;
	} else {
		var esport = preSportsStore.esports[0].id;
	}
	let payload = {
		sport_id: esport,
		hours: 3
	};

	if (payload.sport_id == '' || payload.sport_id == null) return;

	couponsStore.setFixturesLoading(true);
	couponsStore.fetchNextHourFixtures(payload);
});

watch(() => preSportsStore.selected_esport_id, (newSportId, oldSportId) => {
	if (oldSportId > 0) {}

	if (newSportId > 0) {
		let payload = {
			sport_id: newSportId,
			hours: 3
		};

		couponsStore.setFixturesLoading(true);
		couponsStore.fetchNextHourFixtures(payload);
	}
});
watch(() => props.competitions_closed, (newValue) => {
//   alert(`competitions_closed changed: ${newValue}`);
  
  if (newValue) {
    // If competitions are closed, set all to closed (false)
    // This updates all competitions to closed (false)
    competitionStates.value = Object.keys(couponsStore.nextHourFixturesPerCompetition).reduce((acc, key) => {
      acc[key] = false; // Close each competition
      return acc;
    }, {});

    // Optionally you can also clear the state if needed
    // competitionStates.value = {}; 
  } else {
    // If competitions are open, initialize all to open (true)
    competitionStates.value = Object.keys(couponsStore.nextHourFixturesPerCompetition).reduce((acc, key) => {
      acc[key] = true; // Open each competition
      return acc;
    }, {});
  }

//   alert(`Competition states: ${JSON.stringify(competitionStates.value)}`);
});

// Toggle the competition state for a specific competition
const toggleCompetitionState = (index) => {
  // Toggle the specific competition's open/close state
  competitionStates.value[index] = !competitionStates.value[index];
};
</script>

<template>
	<LoaderComponent v-if="couponsStore.fixtures_loading"/>
	<div id="upcoming" class="tab-content" v-else>
		<div v-if="Object.keys(couponsStore.nextHourFixturesPerCompetition).length == 0">
			<div style="margin-top: 20px;">
				<p style="text-align: center; color: white">{{ $t('general.there_are_no_upcoming_events_available') }}</p>
			</div>
		</div>
		<div class="live-info-outer" v-for="(competition, index) in couponsStore.nextHourFixturesPerCompetition" :key="index">

			<div class="live-info-inner">
				<div class="live-info-team">
					<div class="live-match">
						<div class="team-flag">
							<img :src="'/assets' + competition[0].region_icon_url" alt="">
						</div>
						<div class="live-match-name">
							<h3>{{ competition[0].region_name }} - {{ competition[0].competition_name }}</h3>
						</div>
					</div>
				</div>

				<!-- <div class="full-time-top top-title-dash">
					<template v-for="(odd, id) in primary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
				</div> -->

				<!-- <div class="match-goal-top top-title-dash">
					<template v-for="(odd, id) in secondary_overview_market?.odd_names" :key="id">
						<span v-if="odd != ''">{{ odd }}</span>
					</template>
					<span class="bet-column-empty"></span>

				</div> -->
				<span class="bet-column-empty">
					<div @click="toggleCompetitionState(index)" class="market-arrow">
						<a class="market-arrow-icon" :class="competitionStates[index] ? 'market-arrow-icon-closed' : ''"></a>
					</div>
				</span>
			</div>

			<template v-if="!competitionStates[index]">
				<EsportFixtureDoubleMarket
					v-for="(fixture, Index) in competition"
					:key="Index"
					:primary_overview_market="primary_overview_market"
					:secondary_overview_market="secondary_overview_market"
					:fixture="fixture"
				/>
			</template>
		</div>
	</div>
</template>